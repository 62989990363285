/* eslint-disable */
import axios from "axios";
import React, { useEffect, useState } from "react";
import FullscreenLoading from "../components/FullscreenLoading";

export const ConfigContext = React.createContext({
    host: null,
    baseUrl: null,
    notification: null,
    ready: false,
    notificationCenter: false,
    apiUrl: '',
    navOpen: false,
    notify: async () => {},
    initiate: () => {},
    toggleNav: () => {}
    
})

export const ConfigProvider = ({children}) => {

    const [dev, setDev] = useState(false)
    const [host, setHost] = useState(null)
    const [apiUrl, setApiUrl] = useState('')
    const [ready, setReady] = useState(false)
    const [baseUrl, setBaseUrl] = useState(null)
    const [notification, setNotification] = useState(null)
    const [navOpen, setNavOpen] = useState(false)
    const [screenSize, setScreenSize] = useState(0)

    const API_LIST = [
        {label: 'Utviklings API', value: 'https://dev.api.flow.tudu.no/'},
        {label: 'Live API', value: 'https://api.flow.tudu.no/'}
    ]

    function handleResize() {
        switch(true){
            case window.innerWidth > 1024:
                setScreenSize(0)
                break;
            case window.innerWidth < 1024 && window.innerWidth > 767:
                setScreenSize(1)
                break;
            case window.innerWidth < 767:
                setScreenSize(2)
                break;
            default:
        }   
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
    
        return _ => {
          window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        handleResize()
    }, [])
    
    useEffect(() => {
        if(baseUrl){
            axios.defaults = Object.assign(axios.defaults, {
                withCredentials: true,
                baseURL: baseUrl,
            });
            setReady(true)
        }
    }, [baseUrl])


    useEffect(() => {
        switch(window.location.hostname){
            case 'localhost':
            case 'dev.flow.tudu.no':
            case '10.0.0.104':
            case '10.0.0.120':
                setDev(true)
                setHost(window.location.host)
                setBaseUrl('https://api.flow.tudu.no/')
                setApiUrl('api')
                break
            case 'flow.tudu.no':
                setHost(window.location.host)
                setBaseUrl('https://api.flow.tudu.no/')
                setApiUrl('api')
                break
            default:
                setHost(window.location.host)
                setBaseUrl('https://api.flow.tudu.no/')
                
        }
    }, [])


    return <ConfigContext.Provider value={{
        host,
        baseUrl,
        setBaseUrl,
        notification,
        ready,
        apiUrl,
        navOpen,
        screenSize,
        API_LIST,
        dev,
        notify: async (type, title, message) => {
            
            setNotification({type: type, title: title, message: message})

            await new Promise(resolve =>  setTimeout(() => {
                setNotification(null)
            }, 3000))

            return true;
        },
        initiate: () => {
            
            
        },
        toggleNav: () => {
            console.log('Toggling nav')
            console.log(navOpen)
            setNavOpen(!navOpen)
        }
    }}>{

        !host || !baseUrl || !ready ? <FullscreenLoading customText="Laster app..."/> : children
        
    }</ConfigContext.Provider>
}