/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import CheckBox from "../components/CheckBox";
import Drawer from "../components/Drawer";
import DropDown from "../components/DropDown";
import { getNextDeadline } from "../components/Functions";
import ImageStack from "../components/ImageStack";
import Table from "../components/Table";
import { AuthContext } from "../providers/AuthProvider";
import { ConfigContext } from "../providers/ConfigProvider";
import { ConsultantContext } from "../providers/ConsultantProvider";
import { DomainContext } from "../providers/DomainProvider";
import Skeleton from "../components/Skeleteon";

export default function Companies(){

    const { apiCall } = useContext(AuthContext)
    const { baseUrl } = useContext(ConfigContext)
    const { domainCompanies, hasPermission, reloadDomainCompanies } = useContext(DomainContext)

    const [tasksRequiringAttention, setTasksRequiringAttention] = useState(null)
    const [unreadMessages, setUnreadMessages] = useState(null)

    const [companies, setCompanies] = useState(domainCompanies)

    const [search, setSearch] = useState('')
    const [tag, setTag] = useState({label: 'Alle', value: null})
    const [tagOptions, setTagOptions] = useState([{label: 'Alle', value: null}]) 
    const [type, setType] = useState({label: 'Alle', value: null})
    const [typeOptions, setTypeOptions] = useState([{label: 'Alle', value: null}])
    const [showArchived, setShowArchived] = useState(false)
    const [companyFilter, setCompanyFilter] = useState(domainCompanies)

    const [loading, setLoading] = useState(true)

    const [newAreaDrawerOpen, setNewAreaDrawerOpen] = useState(false)

    useEffect(() => {
        setCompanies(domainCompanies)
        filterSearch()
    }, [domainCompanies])

    const loadTemplateSettings = async () => {
       
        const { data } = await apiCall({action: 'get-domain-template-settings'});

        let tags = []
        let types = []

        if(data.status === 1){
            data.data.map((item) => {
                switch(item.type){
                    case 1:
                        tags.push({label: item.name, value: item.id})
                        break
                    case 2:
                        types.push({label: item.name, value: item.id})
                        break
                }
            })
        }

        setTagOptions([{label: 'Alle', value: null}, ...tags])
        setTypeOptions([{label: 'Alle', value: null}, ...types])
        
    }

    useEffect(() => {

        reloadDomainCompanies().then(() => {
            console.log('reloadCompanies Done')
            setLoading(false)
        })
        loadTemplateSettings()
    }, [])

    useEffect(() => {
        filterSearch()
    }, [search, tag, type])

    function filterSearch() {
        var condition = new RegExp(search.toLowerCase());
        const searchFilter = companies.filter(el => condition.test(el.name.toLowerCase()))
        const typeFilter = type.value ? searchFilter.filter(el => el.type ? el.type.id === type.value : false) : searchFilter
        const tagFilter = tag.value ? typeFilter.filter(el => {
            return el.tags.filter(t => t.id === tag.value).length
        }) : typeFilter
        setCompanyFilter(tagFilter)
    }

    useEffect(() => {
        
        //setCompanyFilter(companies)
        countTasksRequiringAttention()
        countUnreadMessages()
    }, [companies])

    const emptyMessage = () => {
        if(companies.length){
            return (
                <div className="h-100 d-flex flex-column justify-content-center align-items-center py-5">
                    <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <FontAwesomeIcon size="2x"   icon={['far', 'circle-exclamation']} className="text-primary" />
                    </div>
                    <div className="text-center">
                        <h6 className="bold text-primary">Ingen resultater matchet ditt søk</h6>
                        <p>Prøv et annet søkeord eller andre filtreringsvalg.</p>
                    </div>
                </div>
            )
        }else{
            return (
                <div className="h-100 d-flex flex-column justify-content-center align-items-center py-5">
                    <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <FontAwesomeIcon size="2x"   icon={['far', 'building']} className="text-primary" />
                    </div>
                    <div className="text-center">
                        <h6 className="bold text-primary">Du har ingen kunder enda</h6>
                        <p>Her vil du se oversikten over alle dine kunder</p>
                        <button onClick={() => setNewAreaDrawerOpen(true)} className="btn btn-primary px-4">Opprett ditt første område</button>
                    </div>
                </div>
            )
        }
    }

    const countTasksRequiringAttention = () => {
        let tempArray = []
        if(companies && companies.length){
            companies.map((company) => {
                if(company && company.active_tasks && company.active_tasks.length){
                    company.active_tasks.map((task) => {
                        const t = getNextDeadline({deadline: task.deadline, subtasks: task.subtasks, lastSubmission: task.lastSubmission})
            
                        if(t.daysUntil <= 7){
                            tempArray.push(task)
                        }
                    })
                }
            })
        }
        setTasksRequiringAttention(tempArray)
    }

    const countUnreadMessages = () => {

        let count = 0
        if(companies && companies.length){
            companies.map((company) => {
                if(company.unreadMessages && company.unreadMessages.length){
                    count += company.unreadMessages.length
                }
            })
        }
        setUnreadMessages(count)
    }

    useEffect(() => {
        if(showArchived){
            loadArchivedCompanies()
        }else{
            setCompanies(companies.filter(c => !c.archived))
        }
    }, [showArchived])

    const loadArchivedCompanies = async () => {
        const { data } = await apiCall({
            action: 'get-domain-archived-companies',
        })
        
        setCompanies([...companies, ...data.data])
        console.log(data)
    }

    const pinCompany = async (id) => {
        const { data } = await apiCall({
            action: 'pin-company',
            id: id
        })
        if(data.status === 1) return data.data
    }

    const PinButton = ({ id, pinned }) => {
        const [loading, setLoading] = useState(false)
        const [isPinned, setIsPinned] = useState(pinned)
        return (
            <button type="button" className="btn btn-link" onClick={async () => {
                setLoading(true)
                const status = await pinCompany(id)
                
                setIsPinned(status === 1)
                setLoading(false)
                
            }}>
                <FontAwesomeIcon className={isPinned ? 'text-primary' : ''} spin={loading} size="lg" icon={[isPinned ? 'fas' : 'fal', loading ? 'spinner-third' : 'thumbtack']} />
            </button>
        )
    }

    return (
        <div className="p-md-3 p-2">
            <div className="p-md-4 p-3 box">
                <div className="pb-4 row">
                    <div className="col">
                        <h3 className="bold">Alle områder</h3>
                    </div>
                    { hasPermission(['createCompany', 'adminCompany']) ? 
                        <div className="col-auto">
                            <button className="btn btn-primary" onClick={() => setNewAreaDrawerOpen(true) }>Opprett område</button>
                        </div>
                    : null}
                    
                </div>
                <div className="row gx-2 gx-md-3">
                    <div className="col-md-4 col-12 mb-md-3 mb-2">
                        <div className="border px-3 py-2 h-100">
                            <div className="row align-items-center">
                                <div className="col-md-12 col order-lg-0">
                                    <div className="border-icon mb-3">
                                        <FontAwesomeIcon icon={['far', 'chart-user']} />
                                    </div>
                                </div>
                                <div className="col-md-12 col-12 order-lg-1 order-2">
                                    <h6 className="hint-text">Alle områder</h6>
                                </div>
                                <div className="col-auto order-lg-2 order-1">
                                    <h2 className="bold">{companies.length}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 mb-2 mb-md-3">
                        <div className="border px-3 py-2 h-100">
                            <div className="row align-items-center">
                                <div className="col-md-12 col order-lg-0">
                                    <div className="border-icon mb-3">
                                        <FontAwesomeIcon icon={['far', 'circle-check']} />
                                    </div>
                                </div>
                                <div className="col-md-12 col-12 order-lg-1 order-2">
                                    <h6 className="hint-text">Oppgaver nær eller over utløpsdato</h6>
                                </div>
                                <div className="col-auto order-lg-2 order-1">
                                    <h2 className="bold">{tasksRequiringAttention ? tasksRequiringAttention.length : <FontAwesomeIcon icon={['fad', 'spinner-third']}  spin />}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 mb-md-3 mb-0">
                        <div className="border px-3 py-2 h-100">
                            <div className="row align-items-center">
                                <div className="col-md-12 col order-lg-0">
                                    <div className="border-icon mb-3">
                                        <FontAwesomeIcon icon={['far', 'user-doctor-message']} />
                                    </div>
                                </div>
                                <div className="col-md-12 col-12 order-lg-1 order-2">
                                    <h6 className="hint-text">Antall ubesvarte samtaler</h6>
                                </div>
                                <div className="col-auto order-lg-2 order-1">
                                    <h2 className="bold">{unreadMessages ? unreadMessages : 0}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <div className="col-md-3 col-12 mb-2">
                        <div className="form-group">
                            <label>Søk i områder</label>
                            <input value={search} onChange={(e) => setSearch(e.target.value)} type="text" className="form-control" placeholder="Søk i navn eller administratorer" />
                        </div>
                    </div>
                    <div className="col-md-3 col-12 mb-2">
                        <div className="form-group">
                            <label>Knagg</label>
                            <DropDown
                                value={tag}
                                onChange={(value) => setTag(value)}
                                options={tagOptions}
                                placeholder="Velg status"
                            />
                        </div>
                    </div>
                    <div className="col-md-3 col-12 mb-2">
                        <div className="form-group">
                            <label>Bransje</label>
                            <DropDown
                                value={type}
                                onChange={(value) => setType(value)}
                                options={typeOptions}
                                placeholder="Velg status"
                            />
                        </div>
                    </div>
                    <div className="col-md-3 col-12 mb-2">
                        <div className="form-group">
                            <CheckBox
                                label="Vis arkiverte"
                                value={showArchived}
                                onChange={(e) => setShowArchived(e)}
                            />
                        </div>
                    </div>
                </div>
                {
                    companyFilter === null ? <h3>Laster</h3> :
                        <Table
                            id="domain-companies"
                            rows={companyFilter}
                            perPage={5}
                            gridTemplateColumns={["repeat(5, 1fr)", "repeat(5, 1fr)", "repeat(5, 1fr)"]}
                            gridTemplateRows={["1fr", "1fr", "repeat(2, 1fr)"]}
                            headers={[
                                { title: 'Navn', key: 'name', sort: 'string'},
                                { title: 'Aktive oppgaver', key: 'active_tasks', sort: 'array'},
                                { title: 'Uleste meldinger', key: 'unread_messages', sort: 'array'},
                                { title: 'Rådgivere', key: 'consultants', sort: 'array'},
                                { title: null }
                            ]}
                            customSort={(rows) => {
                                if(rows){
                                    const sorted = rows.sort((a, b) => b.pinned - a.pinned)
    
                                    
                                    return sorted
                                }
                            }}
                            columns={[
                                {
                                    grid: ["1 / 1 / 2 / 2", "1 / 1 / 2 / 2", "1 / 1 / 2 / 4"],
                                    keys: ['name', 'image', 'alias', 'hash'],
                                    display: (e) => {
                                        return (
                                            <div className="row align-items-center gx-2">
                                                <div className="col-auto ">
                                                    {e.image ? <img src={baseUrl + e.image} style={{width: 40, height: 40, borderRadius: '50%'}} /> : <div className="profile-image-replacement" style={{width: 40, height: 40, fontSize: '1.8rem'}}><span>{e.name.substr(0, 1)}</span></div>}
                                                </div>
                                                <div className="col-auto">
                                                    <Link to={`../${e.hash}/oversikt`} className="medium">{e.name}</Link>
                                                </div>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    grid: ["1 / 2 / 2 / 3", "1 / 2 / 2 / 3", "1 / 4 / 2 / 6"],
                                    keys: ['active_tasks'],
                                    display: (e) => {
                                        return (
                                            <span>
                                                { 
                                                    e.active_tasks?.length > 0 ? 
                                                        <span className="text-success medium">{e.active_tasks.length} oppgaver</span> : 
                                                        ( !loading ? <span>Ingen oppgaver</span> : <Skeleton width={150} height={20} /> )
                                                }
                                            </span>
                                        )
                                    }
                                },
                                {
                                    grid: ["1 / 3 / 2 / 4", "1 / 3 / 2 / 4", "2 / 4 / 3 / 6"],
                                    keys: ['unread_messages'],
                                    display: (e) => {
                                        return(<span>{e.unread_messages?.length > 0 ? <span className="text-warning medium">{e.unread_messages.length} uleste meldinger</span> : ( !loading ? <span>Ingen uleste meldinger</span> : <Skeleton width={150} height={20} /> ) }</span>)
                                    }
                                },
                                {
                                    grid: ["1 / 4 / 2 / 5", "1 / 4 / 2 / 5", "2 / 1 / 3 / 4"],
                                    keys: ['consultants'],
                                    display: (e) => {
                                        
                                        return e.consultants && e.consultants.length && !loading ? <ImageStack users={e.consultants ? e.consultants : []} /> : <Skeleton width={40} height={40} />
                                        
                                    }
                                },
                                {
                                    grid: ["1 / 6 / 2 / 7", "1 / 6 / 2 / 7"],
                                    keys: ['pinned', 'id'],
                                    display: (e) => {
                                        return (
                                            <PinButton {...e} />
                                        )
                                    }
                                }      
                            ]}
                            ifEmpty={emptyMessage()}
                        ></Table>
                }
            </div>
            <Drawer
                open={newAreaDrawerOpen}
                setOpen={setNewAreaDrawerOpen}
            >
                <NewArea setOpen={setNewAreaDrawerOpen} />
            </Drawer>
        </div>
    )
}

function NewArea({ setOpen }){
    return (
        <div className="p-3">
            <div className="row justify-content-between mb-4">
                <div className="col-auto">
                    <button className="btn btn-link" onClick={() => setOpen(false)}>
                        <div className="row gx-2">
                            <div className="col-auto"><FontAwesomeIcon icon={['far', 'arrow-left']} /></div>
                            <div className="col-auto">Områder</div>
                        </div>
                    </button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-link" onClick={() => setOpen(false)}>
                        <FontAwesomeIcon icon={['far', 'xmark']} size="lg" />
                    </button>
                </div>
            </div>
            <div className="">
                <h3 className="bold mb-3">Nytt område</h3>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="h-100 bg-ultra-light w-100 justify-content-center d-flex">
                            <Link to="ny-bedrift" className="text-center d-flex flex-column align-items-center py-5 w-100 box mega-btn  px-5">
                                <div className="mb-3 circle-icon" style={{width: 70, height: 70, backgroundColor: 'var(--success-tint)', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <FontAwesomeIcon icon={['far', 'building']} className="text-primary" size="lg" />
                                </div>
                                <h5 className="bold mb-2">Opprett bedrift</h5>
                                <p>Opprett en bedrift. Bedriften vil være en del av domenet og kan inneholde brukere som samhandler med sine kunder.</p>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="h-100 bg-ultra-light w-100 justify-content-center d-flex">
                            <Link to="nytt-rom" className="text-center d-flex flex-column align-items-center py-5 w-100 box mega-btn  px-5">
                                <div className="mb-3 circle-icon" style={{width: 70, height: 70, backgroundColor: 'var(--success-tint)', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <FontAwesomeIcon icon={['far', 'chalkboard']} className="text-primary" size="lg" />
                                </div>
                                <h5 className="bold mb-2">Opprett rom</h5>
                                <p>Opprett et rom. På samme måte som en bedrift, kan rom også inneholde brukere, men blir ikke registrert som en bedrift.</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}