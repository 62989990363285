/* eslint-disable */
import React, { createContext, createRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Editor from '@draft-js-plugins/editor'
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css'
import Attachments from "../../../components/Attachments";
import PersonPicker from "../../../components/PersonPicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { AuthContext } from "../../../providers/AuthProvider";
import { canDoDask, Entry, formatDate, getNextDeadline,  rteMarkup,  styleText } from "../../../components/Functions";

import ImageStack from "../../../components/ImageStack";
import Modal from "../../../components/Modal";
import { CompanyContext } from "../../../providers/CompanyProvider";
import { DrawerContext } from "../../../components/Drawer";
import { DomainContext } from "../../../providers/DomainProvider";
import { SubTaskContext, SubTaskProvider } from "../../../providers/SubTaskProvider";
import TaskForm from "../../../components/TaskForm";
import Signatures from "../../../components/Signatures";
import TaskLog from "../../../components/TaskLog";
import RightDrawer from "../../../components/RightDrawer";
import UserPhoto from "../../../components/UserPhoto";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import FileUpload from "../../../components/FileUpload";
import ContextMenu, { ContextMenuItem } from "../../../components/ContextMenu";


export default function SubTaskDetails(){
    
    return (
        <SubTaskProvider>
            <Details />
        </SubTaskProvider>
    )
}

function Details(){
    
    const { apiCall, user, company } = useContext(AuthContext)
    const { domainUsers } = useContext(DomainContext)
    const { consultants, companyUsers } = useContext(CompanyContext)
    const { data, submission, fetchTask, submissionFetched, isSigned, archiveTask } = useContext(SubTaskContext)

    const [responsibles, setResponsibles] = useState(data.responsibles)
    const [signModalOpen, setSignModalOpen] = useState(false)

    const [submissionDrawerOpen, setSubmissionDrawerOpen] = useState(false)

    const navigate = useNavigate()

    const updateResponsible = async (item) => {
        
        if(responsibles.filter(e => e.id === item.id).length > 0){
            const removed = responsibles.filter(e => e.id !== item.id)
            const status = await apiCall({action: 'remove-task-responsible', id: data.id, user: item.modUser})
            if(status.data.status === 1){
                setResponsibles(removed)
                fetchTask()
            }
        }else{
            const api = await apiCall({action: 'add-task-responsible', id: data.id, user: item.modUser})
            
            if(api.data.status === 1){
                setResponsibles([...responsibles, item])
                fetchTask()
            }
        }
    }

    const taskSigned = async () => {
        const api = await apiCall({action: 'archive-task-if-signed', task: data.id, submission: submission.id })
        
        fetchTask()
        fetchSubmission()

    }

    const deadline = getNextDeadline({deadline: data.deadline, subtasks: data.subtasks, lastSubmission: data.lastSubmission, submissions: data.submissions})

    return (
        <div className="p-md-3 p-2 pb-5">
            { data.lastSubmission ? (
                <div className="box mb-md-3 mb-2 px-3 py-2 pointer" onClick={() => setSubmissionDrawerOpen(true)}>
                    <div className="row gx-2 align-items-center">
                        <div className="col-auto">
                            <FontAwesomeIcon icon={['far', 'repeat']} />
                        </div>
                        <div className="col">
                            <p className="m-0">Dette er en gjentagende oppgave. Det er registrert flere utførelser av oppgaven.</p>
                        </div>
                        <div className="col-md-auto col-12">
                            <div className="row align-items-center gx-1 justify-content-md-start justify-content-end">
                                <div className="col-auto"><button className="btn btn-link text-primary">Se alle utførelser</button></div>
                                <div className="col-auto"><FontAwesomeIcon className="text-primary" icon={['far', 'arrow-right']} /></div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            ) : null}
            <div className="row gx-2">
                <div className="col-lg-6 mb-md-3 mb-2">
                    <div className="box p-md-4 p-3 h-100">
                        <div className="row justify-content-between align-items-center mb-2">
                            <div className="col">
                                <button className="btn btn-link" onClick={() => navigate(-1)}>
                                    <div className="row gx-1">
                                        <div className="col-auto"><FontAwesomeIcon icon={['far', 'chevron-left']} /></div>
                                        <div className="col">Tilbake</div>
                                    </div>
                                </button>
                            </div>
                            

                            <div className="col-auto">
                                <ContextMenu
                                    buttonEl={<div className="btn-square-icon"><FontAwesomeIcon  size="lg" icon={['far', 'ellipsis-vertical']} /></div>}
                                >
                                    
                                    <ContextMenuItem 
                                        label="Arkiver"
                                        icon={['far', 'box-archive']}
                                        onClick={() => archiveTask()}
                                    />
                                </ContextMenu>
                            </div>
                        </div>
                        <div className="row justify-content-between align-items-center mb-2">
                            
                            <div className="col-auto">
                                <i>{deadline.text}</i>
                            </div>
                            
                            { data.priority ? 
                                <div className="col-auto">
                                    <div className="icon-check-container checked checked-danger">
                                        <div className="icon-check-button ">
                                            <FontAwesomeIcon icon={['fas', 'flag']} />
                                        </div>
                                    </div>
                                </div>
                            : null }
                        </div>

                        <div className="row justify-content-between align-items-center mb-2">

                            <div className="col">
                                <PersonPicker
                                    items={company ? [...companyUsers, ...consultants] : domainUsers}
                                    selected={responsibles}
                                    setSelected={(val) => updateResponsible(val)}
                                    handleManually
                                />
                            </div>
                        </div>
                        <h3 className="bold">{data.name}</h3>
                        { data.description ? <div dangerouslySetInnerHTML={{__html: rteMarkup(data.description)}}></div> : null }
                        
                        { data.attachments && data.attachments.length > 0 ? (
                            <div className="form-group my-4">
                                <label>Vedlegg</label>
                                <Attachments
                                    files={data.attachments}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="col-lg-6 mb-md-3 mb-2">
                    <div className="h-100 box  d-flex flex-column py-2" >
                        <TaskLog Context={SubTaskContext} />
                    </div>
                </div>
                
            </div>
            
            {
                !submissionFetched ? <BoxLoading /> : 
                <div>
                    {
                        data.signature === 2 ? (
                            <div className="signatures py-3">
                                <h5 className="bold">Signaturer</h5>
                                <Signatures users={responsibles} signatures={submission?.signatures} onSign={taskSigned} />
                            </div>
                        ) : null
                    }
                    {
                        data.form ? <TaskForm TaskContext={SubTaskContext} /> : <CommentForm deadline={deadline} /> 
                    }
                </div>
            }
            
            

            <Modal
                open={signModalOpen}
                setOpen={setSignModalOpen}
            >
                <SignModal setOpen={setSignModalOpen} />
            </Modal>

            <RightDrawer
                open={submissionDrawerOpen}
                setOpen={setSubmissionDrawerOpen}
            >
                <Submissions setOpen={setSubmissionDrawerOpen} />
            </RightDrawer>

        </div>
    )
}

function CommentForm({ deadline }){

    const { data, submission, getTask, submissionFetched, updateSubmission, completeTask, archiveTask, deleteTask, isSigned } = useContext(SubTaskContext)
    const { user } = useContext(AuthContext)

    const [signModalOpen, setSignModalOpen] = useState(false)
    
    const drawSubmitButton = () => {
        
        if(!deadline.available){
            return (<button className="btn btn-primary w-100" disabled="disabled">Utførelse av oppgaven åpner senere</button>)
        }

        

        if(data.subtasks && data.subtasks.length){
            let bool = true
            data.subtasks.forEach((subtask) => {
                if(subtask.archivedon === null) bool = false
            })

            if(!bool) return <button className="btn btn-primary w-100" disabled="disabled">Oppgaven kan ikke utføres før underoppgaver er fullført og arkivert</button>
        }

        if(submission?.signatures?.length){
            return <SignButton />
        }

        return (<button type="button" onClick={() => formik.submitForm()} className="btn btn-primary w-100">Fullfør oppgave</button>)
    }

    const SignButton = () => {

        const [canSign, setCanSign] = useState(false)
        const [signed, setSigned] = useState(false)
        const [signedByAll, setSignedByAll] = useState(false)

        useEffect(() => {
            hasSigned()
            checkSignatures()
            assignedToSign()
        }, [])

        const checkSignatures = () => {
            setSignedByAll(data.signatures && (data.responsibles.length === data.signatures.filter(signature => signature.signed).length))
        }

        const hasSigned = () => {
            let bool = false
            if(data.signatures){
                data.signatures.forEach((signature) => {
                    if(signature.signedBy === user.modUser) bool = true
                })
            }
            setSigned(bool)
        }

        const assignedToSign = () => {
            setCanSign(data.responsibles.filter(r => r.id === user.id).length)
        }

        if(signedByAll){
            if(data.form) return <button className="btn btn-primary w-100" onClick={() => alert('lol')}>Send inn skjema</button>
            return <button className="btn btn-primary w-100" onClick={() => completeTask()}>Fullfør oppgaven da</button>
        }
        if(signed) return <button className="btn btn-primary w-100" disabled="disabled">Oppgaven må signeres av alle parter</button>
        if(canSign) return <button className="btn btn-primary w-100" onClick={() => setSignModalOpen(true)}>Signér oppgave</button>

        return <button className="btn btn-success w-100" disabled="disabled">Oppgaven må signeres av alle ansvarlige</button>
    }

    const formik = useFormik({
        initialValues: {
            comment: submission ? submission?.payload?.comment : '',
            attachments: submission && submission?.payload?.attachments ? submission?.payload?.attachments : []
        },
        onSubmit: async (values) => { 
            completeTask(values)
        }
    });

    useEffect(() => {
        if(formik.dirty){
            updateSubmission(formik.values)
        }
        
    }, [formik])

    if(!isSigned){
        return (
            <div className="box p-3 mb-3">
                <h4 className="bold">Kommentar til utførelse</h4>
                { submission?.payload?.comment ? <div className="mb-2">
                    <p>{submission.payload.comment}</p>
                </div> : null }
                            
                { submission?.payload?.attachments && submission?.payload?.attachments.length > 0 ? (
                    <div className="form-group my-4">
                        <label>Vedlegg</label>
                        <Attachments
                            files={submission?.payload?.attachments}
                        />
                    </div>
                ) : null}
                <div className="bg-warning-tint rounded p-3">
                    <div className="row gx-2 align-items-center">
                        <div className="col-auto"><FontAwesomeIcon icon={['fad', 'circle-exclamation']} size="lg" className="text-warning" /></div>
                        <div className="col-auto">
                            <p className="m-0">Oppgaven må signeres av alle</p>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className="box p-3 mb-3">
                    <h4 className="bold">Kommentar til utførelse (valgfritt)</h4>
                    <textarea placeholder="Skriv eventuell kommentar her.." value={formik.values.comment} onChange={(e) => formik.setFieldValue('comment', e.target.value)} className="form-control mb-2" rows="3"></textarea>
                    <FileUpload 
                        files={formik.values.attachments}
                        setFiles={(val) => {
                            console.log(val)
                            formik.setFieldValue('attachments', val)
                        }}
                    />
                </div>
                
                {drawSubmitButton()}
            </form>

            <Modal
                open={signModalOpen}
                setOpen={setSignModalOpen}
            >
                <SignModal setOpen={setSignModalOpen} />
            </Modal>
        </div>
    )
}

function SignModal({ setOpen }){

    const { signTask, data, formValidation } = useContext(SubTaskContext)

    const FormError = () => {
        return (
            <div className="p-4" style={{maxWidth: 400}}>
                <h4 className="bold">Signering av oppgave</h4>
                <p>Oppgaven kan ikke signeres før skjemaet er fullført. Etter signering av oppgaven kan ikke skjemaet endres</p>
                <button className="btn btn-black-outline w-100" onClick={() => setOpen(false)}>Lukk</button>
            </div>
        )
    }

    if(data.form){
        let error = false
        if(!formValidation) return <FormError />
        Object.keys(formValidation).map((k) => {
            if(formValidation[k].error) error = true
        })

        if(error) return <FormError />
    }

    const sign = async () => {
        await signTask()
        setOpen(false)
    }    

    return (
        <div className="p-4" style={{maxWidth: 400}}>
            <h4 className="bold">Signering av oppgave</h4>
            <p>Denne oppgaven fullføres ved at nødvendige parter signerer. Når alle har signert vil oppgaven <strong>fullføres og arkiveres</strong>.</p>
            <div className="row">
                <div className="col-6">
                    <button className="btn btn-black-outline w-100" onClick={() => setOpen(false)}>Avbryt og lukk</button>
                </div>
                <div className="col-6">
                    <button className="btn btn-primary w-100" onClick={() => sign()}>Signer oppgave</button>
                </div>
            </div>
        </div>
    )
}

function Submissions({ setOpen }){

    const { data, changeSubmission } = useContext(SubTaskContext)
    const { apiCall } = useContext(AuthContext)
    const [submissions, setSubmissions] = useState(null)
    const getSubmissions = async () => {
        
        const status = await apiCall({action: 'get-all-task-submissions', id: data.id})
        
        if(status.data.status === 1){
            setSubmissions(status.data.data)
        }
        
    }

    useEffect(() => {
        getSubmissions()
    }, [])

    const SubmissionItem = ({ id, archivedon, archivedby }) => {
        return (
            <div className="submission-drawer-item">
                <div className="row gx-2">
                    <div className="col-auto">
                        <div className="submission-drawer-dot">
                            <div className="dot"></div>
                        </div>
                    </div>
                    <div className="col">
                        <span className="hint-text mb-2 d-block">{formatDate(archivedon, 'DD. MMMM YYYY')}</span>
                        {archivedby ? (
                            <div className="row gx-2 align-items-center pb-4 mr-5">
                                <div className="col-auto">
                                    <UserPhoto photo={archivedby.photo} name={archivedby.fullname} size={[40, 40, 40]} />
                                </div>
                                <div className="col">
                                    Utført av {archivedby.fullname}
                                </div>
                            </div>
                        ) : null}
                        
                    </div>
                    <div className="col-auto align-self-center">
                        <button type="button" className="btn btn-primary btn-sm" onClick={() => changeSubmission(id)}  style={{marginLeft: 30}}>Se oppgave</button>
                    </div>
                </div>
            </div>
        )
    }
    
    return (
        <div>
            <div style={{borderBottom: '1px solid #000'}}>
                <div className="row pb-2">
                    <div className="col">
                        <h6 className="bold">Utførelser av {data.name}</h6>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-link" onClick={() => setOpen(false)}>
                            <FontAwesomeIcon icon={['fas','xmark']} />
                        </button>
                    </div>
                </div>
            </div>
            {submissions === null ? 
                (
                    <div className="d-flex justify-content-center py-5">
                        <FontAwesomeIcon spin size="2x" icon={['fad', 'spinner-third']} />
                    </div>
                )
                :
                (
                    <div className="py-4">
                    {
                        submissions.length > 0 ? submissions.map((el, key) => {
                            
                            return <SubmissionItem key={key} {...el} />
                        }) : null
                    }
                    </div>
                )
            }
            
            
        </div>
    )
}

function Skeleton(){
    return (
        <div className="p-3">
            <div className="row gx-2 mb-3">
                <div className="col-lg-6">
                    <div className="box p-4 h-100">
                        <div className="row justify-content-between align-items-center mb-5">
                            <div className="col">
                                
                            </div>
                            <div className="col-auto">
                                <i>Frist: </i>
                            </div>
                            
                            <div className="col-auto">
                                <div className="icon-check-container checked-danger">
                                    <div className="icon-check-button ">
                                        <FontAwesomeIcon icon={['fas', 'flag']} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 className="bold"></h3>
                        
                        
                            <div className="form-group my-4">
                                <label>Vedlegg</label>
                                
                            </div>
                        
                        <div className="form-group my-4">
                            <h5 className="bold">Underoppgaver</h5>
                            <div className="border">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 ">
                    <div className="h-100 box  d-flex flex-column">
                        <div className="p-4 col">
                            
                        </div>
                        <div className="p-3 border-top">
                            <div className="row align-items-center">
                                <div className="col">
                                    
                                </div>
                                <div className="col-auto">
                                    <button className="btn btn-link">
                                        <FontAwesomeIcon icon={['far', 'paper-plane-top']} />
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
    )
}